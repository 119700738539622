<template>
  <client-only>
    <div class="download-jumbo">
      <div class="container">
        <div class="row">
          <div class="col">
            <div class="col-12 col-lg-7 offset-lg-5">
              <div class="download-jumbo_title">
                <p>Скачайте мобильное приложение</p>
                <span>и получайте  <strong>удвоенный кэшбэк</strong> <br> при каждом заказе!</span>
              </div>
              <div class="download-jumbo_features">
                <div class="download-jumbo_features_item">
                  <div class="download-jumbo_features_icon">
                    <img src="/percent.svg" alt="">
                  </div>
                  <p>Промокоды на подарки и скидки</p>
                </div>
                <div class="download-jumbo_features_item">
                  <div class="download-jumbo_features_icon">
                    <img src="/nophone.svg" alt="">
                  </div>
                  <p>Заказ без подтверждения оператора</p>
                </div>
                <div class="download-jumbo_features_item">
                  <div class="download-jumbo_features_icon">
                    <img src="/mobile-app/benefit-new.svg" alt="">
                  </div>
                  <p>Новинки меню в приложении</p>
                </div>
                <div class="download-jumbo_features_item">
                  <div class="download-jumbo_features_icon">
                    <img src="/mobile-app/benefit-double.svg" alt="">
                  </div>
                  <p>Удвоение Фокскойнов при каждом заказе</p>
                </div>
              </div>
              <MobileAppsGroup class="d-lg-none">
                <MobileApp platform="apple" />
                <MobileApp platform="gplay" />
              </MobileAppsGroup>
              <div v-if="!allowDirectDownloadMobileApp" class="download-jumbo_form">
                <small>Мы отправим вам прямую ссылку для скачивания приложения. Без лишнего спама.</small>
                <form @submit.prevent="sendMobileApp" action="" class="download-jumbo_form_inner">
                  <div class="download-jumbo_form_input">
                    <!--									<input type="text" v-model="phone" placeholder="+7 (___) ___-__-__">-->
                    <InputText
                      v-model="phone"
                      :mask="phoneInputMask"
                      placeholder="+7(___) ___-__-__"
                    />
                  </div>
                  <div class="download-jumbo_form_input">
                    <Button
                      :loading="loading"
                      :disabled="!phone"
                      type="submit"
                    >
                      Получить ссылку
                    </Button>
                  </div>
                </form>
              </div>
              <MobileAppsGroup class="d-none d-md-flex">
                <MobileApp platform="apple" />
                <MobileApp platform="gplay" />
              </MobileAppsGroup>
            </div>
          </div>
        </div>
      </div>

      <Modal
        :open="showModal"
        @close="showModal = false"
        initial-width="550px"
      >
        <div v-if="showConfirmStep" class="login_body">
          <div class="login_body_title">
            <p>
              Вам отправлен SMS <br>
              код-подтверждения!
            </p>
          </div>
          <div class="login_body_phone">
            <p>
              Введите код подтверждения, мы отправим вам ссылку
              на скачивание и зарегистрируем в нашей системе
            </p>
            <strong>{{ phone }}</strong>
            <div class="login_body_phone_again">
              <span v-show="this.againTimer > 0">{{ againTimerObserver }}</span>
              <a @click.prevent="sendAuthCode" href="#">
                Отправить код повторно
              </a>
            </div>
          </div>
          <div class="login_body_code">
            <client-only>
              <form @submit.prevent="login">
                <CodeInput
                  :autoFocus="true"
                  @change="setVerificationCode"
                  :fields="4"
                  class-name="verification_field"
                />
                <button type="submit" hidden />
              </form>
            </client-only>
          </div>
          <div class="login_body_form_submit">
            <Button :disabled="!isFinalFormValid" @click="login">
              Получить ссылку на скачивание
            </Button>
          </div>
          <loader-over-screen v-if="loading" />
        </div>
        <div v-if="showFinalStep" class="final-step">
          <div class="final-step-image">
            <img src="/mobile-app/link-icon.svg" alt="">
          </div>
          <div class="final-step-title">
            Ссылка отправлена!
          </div>
          <div class="final-step-text">
            Мы отправили вам SMS с ссылкой <br> на скачивание мобильного приложения.
          </div>
          <div class="final-step-actions">
            <Button @click="showModal = false" block>
              Окей, понятно
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  </client-only>
</template>

<script>
import InputText from '@/components/UI/InputText'
import { mapGetters } from 'vuex'
import MobileAppsGroup from './../UI/MobileAppsGroup'
import MobileApp from './../UI/MobileApp'
import Button from '~/components/UI/Button'
import Modal from '~/components/page/Modal/Modal'
import LoaderOverScreen from '~/components/UI/LoaderOverScreen'
import UserDeviceDetect from '~/mixins/user-device-detect'

export default {
  components: { LoaderOverScreen, Modal, Button, MobileAppsGroup, MobileApp, InputText },
  mixins: [UserDeviceDetect],
  data () {
    return {
      loading: false,
      phone: '',
      verificationCode: '',
      showModal: false,
      showConfirmStep: false,
      showFinalStep: false,
      againTimer: 0,
    }
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'auth/loggedIn',
      user: 'auth/user',
    }),
    phoneInputMask () {
      return this.isLoggedIn ? '+9(999) 999-99-99' : '+7(999) 999-99-99'
    },
    authUserPhone () {
      return this.isLoggedIn && this.user ? this.user.phone : ''
    },
    formattedPhone () {
      return this.phone ? this.phone.match(/[0-9]/g).join('') : null
    },
    againTimerObserver () {
      const min = Math.floor(this.againTimer / 60)
        ? Math.floor(this.againTimer / 60)
        : '0'
      const sec = this.againTimer % 60 ? this.againTimer % 60 : '00'

      return `${min}:${sec}`
    },
    isFinalFormValid () {
      return this.verificationCode.length === 4
    },
  },
  created () {
    if (this.isLoggedIn) {
      this.phone = this.authUserPhone
    }
  },
  methods: {
    sendMobileApp () {
      if (this.isLoggedIn && this.formattedPhone === this.authUserPhone) {
        this.sendAppLink()
      } else {
        this.sendAuthCode()
      }
    },
    sendAppLink () {
      if (this.againTimer > 0) {
        const sec = this.againTimer
        this.$toast.error(`Повторно отправить ссылку можно через ${sec} сек.`, { duration: 3000 })
        return
      }

      this.loading = true
      this.$store.dispatch('client/sendAppLink')
        .then(() => {
          this.againTimer = 60
          this.setVerificationInterval()
          this.showFinalModal()
        })
        .finally(() => {
          this.loading = false
        })
    },
    sendAuthCode () {
      if (this.againTimer > 0) {
        const sec = this.againTimer
        this.$toast.error(`Повторно отправить код можно через ${sec} сек.`, { duration: 3000 })
        return
      }

      this.loading = true
      this.$store.dispatch('auth/sendCode', this.phone)
        .then(() => {
          this.againTimer = 60
          this.setVerificationInterval()
          this.showConfirmModal()
        })
        .catch((err) => {
          this.$toast.error(err?.response?.data?.message, { duration: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    },
    login () {
      this.loading = true

      this.$store
        .dispatch('auth/login', {
          phone: this.formattedPhone,
          password: this.verificationCode,
          sendMobileAppLink: true,
        })
        .then(() => {
          this.showFinalModal()

          this.$store.dispatch('cart/fetchCart')
            .catch(err => console.error('login page - cart/fetchCart - error', err))
        })
        .catch(() => {
          this.$toast.error('Не верный код', { duration: 3000 })
        })
        .finally(() => {
          this.loading = false
        })
    },

    showConfirmModal () {
      this.showModal = true
      this.showConfirmStep = true
      this.showFinalStep = false

      this.$nextTick(() => {
        $('.react-code-input input').eq(0).focus()
      })
    },

    showFinalModal () {
      this.showModal = true
      this.showConfirmStep = false
      this.showFinalStep = true
    },

    closeModal () {
      this.showModal = true
    },

    setVerificationInterval () {
      this.againTimerFunc = setInterval(() => {
        this.againTimer--

        if (this.againTimer <= 0) {
          clearInterval(this.againTimerFunc)
          this.againTimer = 0
        }
      }, 1000)
    },
    setVerificationCode (code) {
      this.verificationCode = code
    },
  },
}
</script>

<style lang="less">
.download-jumbo {
  width: 100%;
  display: flex;
  margin-bottom: 40px;

  @media screen and (max-width: 992px) {
    .mobile-apps {
      justify-content: center;
      margin-top: 20px;
    }
  }

  .row {
    background-image: url('/mobile-app/app-min.png');
    background-size: contain;
    background-position: bottom left;
    background-repeat: no-repeat;

    @media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
      background-image: url('/mobile-app/app-x2-min.png');
    }

    @media screen and (max-width: 991px) {
      background-image: none;
    }
  }

  &_title	{
    margin-bottom: 30px;

    p {
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: 500;
      font-size: 50px;
      color: black;
      line-height: 50px;
      padding-bottom: 10px;

      @media screen and (max-width: 1200px) {
        font-size: 40px;
        padding-bottom: 2px;
      }

      @media screen and (max-width: 992px) {
        font-size: 32px;
        align-items: center;
        padding-bottom: 18px;
        line-height: 1.2;
      }

      @media screen and (max-width: 500px) {
        font-size: 25px;
        align-items: center;
        padding-bottom: 18px;
        line-height: 1.2;
      }
    }

    span {
      font-family: 'Roboto', Arial, sans-serif;
      font-size: 30px;
      color: black;

      @media screen and (max-width: 1200px) { font-size: 25px; }
      @media screen and (max-width: 992px) { text-align: center; }
      @media screen and (max-width: 500px) { font-size: 22px }

      strong {
        height: 40px;
        display: inline-flex;
        align-items: center;
        background: rgb(255,152,0);
        background: linear-gradient(180deg, rgba(255,152,0,1) 0%, rgba(255,78,0,1) 100%);
        padding: 0 10px;
        border-radius: 15px;
        font-family: 'Roboto', Arial, sans-serif;
        color: white;

        @media screen and (max-width: 1200px) {
          height: 36px;
        }
      }
    }
  }

  &_features {
    display: flex;
    flex-direction: column;
    margin-bottom: 25px;

    @media screen and (max-width: 1200px) { margin-bottom: 18px; }
    @media screen and (max-width: 992px) {
      /*background-image: url('/mobile-app/mobile-bg.png');
      background-size: contain;
      background-position: top center;
      background-repeat: no-repeat;
      padding-top: 360px;*/
      margin-bottom: 40px;
    }

    &_item {
      display: flex;
      align-items: center;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      p {
        font-family: 'Roboto', Arial, sans-serif;
        font-size: 18px;
        color: black;

        @media screen and (max-width: 992px) {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }

    &_icon {
      margin-right: 25px;

      /*	@media screen and (max-width: 1200px) { margin-right: 27px; }
        @media screen and (max-width: 992px) { margin-right: 21px; }*/

      img {
        width: 40px;
        height: 40px;
        object-fit: contain;
        object-position: center;
      }
    }
  }

  &_form {
    margin-bottom: 70px;

    @media screen and (max-width: 1200px) { margin-bottom: 38px; }
    @media screen and (max-width: 992px) { margin-bottom: 0; }

    small {
      display: block;
      font-family: 'Roboto', Arial, sans-serif;
      font-size: 14px;
      color: black;
      padding-bottom: 20px;

      @media screen and (max-width: 992px) {
        font-size: 20px;
        text-align: center;
      }
    }

    &_inner {
      display: flex;
      align-items: stretch;
      justify-content: space-between;

      @media screen and (max-width: 992px) {
        flex-direction: column;
      }
    }

    &_input {
      width: calc((100% / 2) - 7px);

      @media screen and (max-width: 992px) {
        width: 100%;

        &:not(:last-child) { margin-bottom: 13px; }
      }

      & > * { width: 100%; }

      input {
        height: 58px;
        display: flex;
        align-items: center;
        background-color: white;
        padding: 18px 40px;
        border: 2px solid #D5D5D5;
        border-radius: 15px;
        font-family: 'Roboto', Arial, sans-serif;
        font-size: 16px;
        color: black;
        box-sizing: border-box;

        &::placeholder {
          font-family: 'Roboto', Arial, sans-serif;
          font-size: 16px;
          color: black;
        }
      }

      button {
        height: 58px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(255,152,0);
        background: linear-gradient(180deg, rgba(255,152,0,1) 0%, rgba(255,78,0,1) 100%);
        font-family: 'Roboto', Arial, sans-serif;
        font-size: 14px;
        color: white;
        padding: 20px 40px;
        border: none;
        border-radius: 15px;
        cursor: pointer;
      }
    }

  }

  .mobile-apps {

    &_item {
      margin-bottom: 0;

      &:not(:last-child) {
        margin-right: 15px;
      }

      img {
        display: block;
        height: 55px;

        @media screen and (max-width: 500px) { height: 40px; }
      }

    }
    @media screen and (max-width: 992px) { margin-bottom: 30px; }
  }

  .login_body {
    max-width: 100%;
    width: 435px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    @media screen and (max-width: 768px) {
      margin-bottom: 100px;
    }

    &_title {
      margin-bottom: 30px;

      @media screen and (max-width: 540px) {
        margin-bottom: 45px;
      }

      p {
        font-family: 'Roboto Slab', Arial, sans-serif;
        font-weight: 500;
        font-size: 25px;
        text-align: center;
        line-height: 30px;

        @media screen and (max-width: 540px) {
          font-size: 20px;
        }
      }
    }

    &_form {
      max-width: 100%;
      width: 290px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      position: relative;

      &_text {
        margin-bottom: 30px;

        @media screen and (max-width: 540px) {
          margin-bottom: 17px;
        }

        span {
          display: block;
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 300;
          font-size: 14px;
          text-align: center;
          margin-bottom: 28px;
        }

        input {
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 300;
          text-align: center;
          color: black;
          border: 1px solid #ff7500;

          &::placeholder {
            font-family: 'Roboto', Arial, sans-serif;
            font-weight: 300;
            text-align: center;
            color: black;
          }
        }
      }

      &_checkbox {
        margin-bottom: 30px;

        @media screen and (max-width: 540px) {
          margin-bottom: 27px;
        }

        &.agree-block {
          @media screen and (min-width: 380px) {
            margin-left: -20px;
            margin-right: -20px;
          }
        }
      }

      &_submit {
        max-width: 100%;
        width: 290px;
        display: flex;
        flex-direction: column;
        align-items: stretch;

        button {
          width: 100%;

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }
      }
    }

    &_phone {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-family: 'Roboto', Arial, sans-serif;
      margin-bottom: 38px;

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        padding-bottom: 7px;
      }

      strong {
        font-size: 17px;
        text-align: center;
        padding-bottom: 18px;
      }

      &_again {
        display: flex;
        align-items: center;

        span {
          width: 63px;
          height: 26px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-shrink: 0;
          background-color: #f2f2f2;
          font-family: 'Roboto', Arial, sans-serif;
          font-size: 14px;
          border-radius: 5px;
          margin-right: 18px;
        }

        a {
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 300;
          font-size: 14px;
          color: black;
          text-decoration: none;
          border-bottom: 1px dashed #000;
        }
      }
    }

    &_code {
      display: flex;
      align-items: stretch;
      margin-bottom: 46px;
      position: relative;

      input {
        padding: 0 !important;
      }

      &_item {
        width: 45px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: #f2f2f2;
        font-family: 'Roboto Slab', Arial, sans-serif;
        font-weight: 500;
        color: #003cff;
        text-align: center;
        border: none;
        border-radius: 15px;

        &:not(:last-child) {
          margin-right: 14px;
        }
      }
    }
  }

  .verification_field {
    .react-code-input {
      display: flex;
      input {
        font-family: 'Roboto Slab', Arial, sans-serif;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 45px !important;
        height: 60px !important;
        background-color: #f2f2f2;
        font-weight: 500;
        font-size: 50px;
        color: #003cff;
        text-align: center;
        border: none !important;
        border-radius: 15px !important;
        margin-right: 14px;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .final-step {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-image {

    }

    &-title {
      font-weight: 500;
      font-size: 25px;
      line-height: 28px;
      text-align: center;
      margin-bottom: 40px;
    }

    &-text {
      font-weight: 300;
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      margin-bottom: 35px;
    }

    &-actions {
      width: 290px;
      max-width: 100%;
    }
  }

  @media screen and (max-width: 992px) {
    padding-left: 18px;
    padding-right: 18px;
  }

  @media screen and (max-width: 992px) {
    background-image: url('/mobile-app/mobile-bg-2.png');
    background-position: top center;
    background-repeat: no-repeat;
    padding-top: 400px;
  }

  @media screen and (max-width: 500px) {
    padding-left: 0;
    padding-right: 0;
  }

}
</style>
