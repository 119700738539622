<template>
  <div class="product">
    <div v-if="!isInlineCard" class="container">
      <div class="row">
        <div class="col">
          <div class="product_breadcrumbs">
            <Breadcrumbs
              :parentCategory="parentCategoryToBreadcrumbs"
              :currentName="getTitle"
              type="menuItem"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container product_container">
      <div class="row product_row">
        <div class="col-12 col-lg-6 product_col_photo">
          <ProductPhoto
            :photo="getPhoto"
            :attrs="getStickers"
            :isPizza="isPizza"
            :isDoubling="isDoubling"
          />
        </div>
        <div class="col-12 col-lg-6 product_col_info">
          <div class="product_info">
            <div class="product_info_title">
              <h1>{{ getTitle }}</h1>
            </div>
            <div class="product_info_description">
              <p>{{ getDescription }}</p>
            </div>
            <div class="product_info_order-prop">
              <template v-if="getType === 'pizza'">
                <div class="product_info_order-prop_item">
                  <TumblerProp
                    v-model="modifications[i].active"
                    v-if="v.type === 'single'"
                    :id="`cb-${ item.id }-${ i }`"
                  >
                    {{ modifications[i].nameOn }}
                  </TumblerProp>
                </div>
                <div class="product_info_order-prop_item">
                  <ButtonTumbler
                    v-model="modifications[i].active"
                    v-if="v.type === 'select'"
                    :mini="true"
                    :nameOn="modifications[i].nameOn"
                    :nameOff="modifications[i].nameOff"
                    :active="modifications[i].active"
                  />
                </div>
              </template>
              <template v-else>
                <div class="product_info_order-prop_item">
                  <template v-for="(v, i) in getModifications">
                    <div :key="`modcardmodal-${ i }`">
                      <ButtonTumbler
                        v-model="modifications[i].active"
                        v-if="v.type === 'select'"
                        :mini="true"
                        :nameOn="modifications[i].nameOn"
                        :nameOff="modifications[i].nameOff"
                        :active="modifications[i].active"
                      />
                      <!-- Модификатор single -->
                      <TumblerProp
                        v-model="modifications[i].active"
                        v-if="v.type === 'single'"
                        :id="`cb-${ item.id }-${ i }`"
                      >
                        {{ modifications[i].nameOn }}
                      </TumblerProp>
                    </div>
                  </template>
                </div>
              </template>
            </div>
            <div class="product_info_consist">
              <p>Состав:<br>{{ getConsist }}</p>
            </div>
            <div v-if="getModifiedTechnicalCard" class="product_info_attrs">
              <div class="product_info_attrs_item">
                <span>Вес</span>
                <strong>
                  <icon-weight />
                  {{ getModifiedTechnicalCard.weight + ' г' }}
                </strong>
              </div>
              <div class="product_info_attrs_item">
                <span>Белки</span>
                <strong>{{ getModifiedTechnicalCard.proteins + ' г' }}</strong>
              </div>
              <div class="product_info_attrs_item">
                <span>Жиры</span>
                <strong>{{ getModifiedTechnicalCard.fats + ' г' }}</strong>
              </div>
              <div class="product_info_attrs_item">
                <span>Углеводы</span>
                <strong>{{ getModifiedTechnicalCard.carbohydrates + ' г' }}</strong>
              </div>
              <div class="product_info_attrs_item">
                <span>Калорийность</span>
                <strong>{{ getModifiedTechnicalCard.calories + ' кКал' }}</strong>
              </div>
            </div>
            <div class="product_submit">
              <div class="product_submit_price">
                <div class="product_submit_price_inner">
                  <del v-if="getOldPrice">{{ getOldPrice }}<Ruble /></del>
                  <strong>{{ getModifiedPrice }}<Ruble /></strong>
                </div>
                <div v-if="getBonus" class="product_submit_price_bonus">
                  <img src="/setcoins-icon.svg" alt="">
                  <div>
                    <span>ваш бонус</span>
                    <br>
                    <strong>+{{ getBonus }} <Ruble /></strong>
                  </div>
                </div>
              </div>
              <div class="product_submit_incart">
                <Button @click="remoteCartPush" class="button_border">
                  <font-awesome-icon :icon="['fas', 'shopping-cart']" />В корзину
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isInlineCard" class="container">
      <!-- <div class="row">
				<div class="col">
					<div class="product_recomended">
						<InnerTitle>
							<template v-slot:title>Рекомендуем попробовать</template>
						</InnerTitle>
					</div>
				</div>
			</div> -->
      <!-- <div class="row" style="margin-bottom: 35px">
				<div class="col">
					<div class="product_recomended-slider">
						<RecomendedSlider />
					</div>
				</div>
			</div> -->
    </div>
    <!-- <template v-if="!isInlineCard">
			<div class="product_download" v-if="getType !== 'pizza'">
				<DownloadJumbo />
			</div>
		</template> -->
  </div>
</template>

<script>
import Breadcrumbs from '@/components/UI/Breadcrumbs'
import ProductPhoto from '@/components/page/Product/ProductPhoto'
import ButtonTumbler from '@/components/UI/ButtonTumbler'
import Ruble from '@/components/UI/Ruble'
import Button from '@/components/UI/Button'
import InnerTitle from '@/components/UI/InnerTitle'
import RecomendedSlider from '@/components/page/RecomendedSlider'
import DownloadJumbo from '@/components/page/DownloadJumbo'
import TumblerProp from '@/components/UI/TumblerProp'

import global from '@/mixins/global'

export default {
  components: { Breadcrumbs, ProductPhoto, ButtonTumbler, Ruble, Button, InnerTitle, RecomendedSlider, DownloadJumbo, TumblerProp },
  mixins: [global],
  props: {
    item: {
      type: Object,
      default () {
        return null
      },
    },
    isInlineCard: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      modifications: [],
    }
  },
  computed: {
    parentCategoryToBreadcrumbs () {
      return this.item && this.item.categories && this.item.categories[0]
        ? { name: this.item.categories[0].name, path: `/menu/${this.item.categories[0].slug}` }
        : {}
    },
    getUuid () { return this.item ? this.item.id : null },
    getBonus () {
      if (this.item) {
        return this.modificationActive
          ? this.getModifications[0].foxCoinsAdd
          : this.item.foxCoinsAdd
      }
	    return 0
    },
    getType () { return this.item ? this.item.type : null },
    getTitle () { return this.item ? this.item.name : null },
    getDescription () { return this.item ? this.item.description : null },
    getConsist () { return this.item ? this.item.composition : null },
    getPhoto () { return this.item ? this.item.image : null },
    getPrice () { return this.item ? this.item.price : null },
    modificationActive () {
      return (this.modifications && this.modifications.length) ? this.modifications[0].active : false
    },
    getModifiedPrice () {
      const IS_MODIFY = this.modifications.length ? this.modifications[0].active : false

      return IS_MODIFY ? this.modifications[0].price : this.getPrice
    },
    getOldPrice () { return this.item ? this.item.oldPrice : null },
    getStickerType () { return this.item ? this.item.stickerType : '' },
    getStickerMarketing () { return this.item ? this.item.stickerMarketing : '' },
    getStickers () {
      return {
        type: this.getStickerType,
        marketing: this.getStickerMarketing,
      }
    },
    getModifications () { return this.item ? this.item.modifications : [] },
    getTechnicalCard () { return this.item ? this.item.technicalCard : null },
    getModifiedTechnicalCard () {
      const IS_MODIFY = this.modifications.length ? this.modifications[0].active : false

      return IS_MODIFY ? this.modifications[0].technicalCard : this.getTechnicalCard
    },
    isPizza () { return this.item ? this.item.categories.some(v => v.slug === 'pizza') : false },
    isDoubling () { return this.item && this.item.isDoubling },
  },
  created () {
    this.modifications = this.getModifications.map((v) => {
      return {
        id: v.modification_id,
        active: v.active,
        nameOn: v.nameOn,
        nameOff: v.nameOff,
        price: v.actualPrice,
        technicalCard: v.actualCharacteristicTechCard,
      }
    })
  },
  methods: {
    remoteCartPush () {
      this.addToCart(
        this.item,
        this.modifications.length ? this.modifications[0].active : false,
      )
      this.$nuxt.$emit('close-modal')
    },
  },
}
</script>

<style lang="less">
.product {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  @media screen and (max-width: 768px) {
    overflow: hidden;
  }

  &_breadcrumbs {
    padding-top: 13px;
    padding-bottom: 27px;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &_info {
    background-color: white;
    margin-top: 40px;
    margin-left: 13px;

    @media screen and (max-width: 1200px) {
      margin-top: 0;
      margin-left: 0;
    }
    @media screen and (max-width: 768px) {
      padding: 37px 23px 12px 23px;
      border-top-left-radius: 30px;
      border-top-right-radius: 30px;
    }

    &_title {
      margin-bottom: 30px;

      @media screen and (max-width: 1200px) {
        margin-bottom: 17px;
      }

      p, h1 {
        font-family: 'Roboto Slab', Arial, sans-serif;
        font-weight: 500;
        font-size: 25px;
      }
    }

    &_description {
      min-height: 70px;
      margin-bottom: 25px;

      @media screen and (max-width: 1200px) {
        min-height: unset;
      }

      p {
        font-family: 'Roboto', Arial, sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &_order-prop {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      &_item {
        margin-bottom: 10px;
      }

      @media screen and (max-width: 1200px) {
        margin-bottom: 27px;
      }
    }

    &_consist {
      margin-bottom: 30px;

      @media screen and (max-width: 1200px) {
        margin-bottom: 28px;
      }

      p {
        font-family: 'Roboto', Arial, sans-serif;
        font-weight: 300;
        font-size: 14px;
        line-height: 18px;
      }
    }

    &_attrs {
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      margin-bottom: 20px;

      @media screen and (max-width: 1200px) {
        margin-bottom: 15px;
      }

      &_item {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        background-color: #F6F6F6;
        font-family: 'Roboto', Arial, sans-serif;
        padding: 8px 13px 13px 13px;
        border-radius: 15px;
        margin-bottom: 10px;

        &:not(:last-child) {
          margin-right: 10px;
        }

        span {
          font-size: 12px;
          line-height: 18px;
          text-align: center;
          margin-bottom: 8px;
        }

        strong {
          font-weight: bold;
          font-size: 12px;
          text-align: center;

          svg {
            font-size: 9px;
            margin-right: 4px;
          }
        }
      }
    }
  }

  &_attrs {
    &_personal {
      height: 35px;
      display: flex;
      align-items: center;
      background-color: #FF005F;
      font-family: 'Roboto Slab', Arial, sans-serif;
      font-weight: bold;
      font-size: 14px;
      color: white;
      padding: 5px 11px;
      border-radius: 10px;
      box-sizing: border-box;

      @media screen and (max-width: 1200px) {
        height: 30px;
      }
    }
  }

  &_submit {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_price {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      &_inner {
        position: relative;
        margin-right: 30px;
        margin-top: 3px;

        del {
          font-family: 'Roboto', Arial, sans-serif;
          font-size: 16px;
          color: #B7B7B7;
          position: absolute;
          top: -15px;
        }

        strong {
          font-family: 'Roboto', Arial, sans-serif;
          font-weight: 500;
          font-size: 30px;
          color: black;
        }
      }

      &_bonus {
        display: flex;
        align-items: center;
        font-family: 'Roboto', Arial, sans-serif;

        @media screen and (max-width: 768px) {
          flex-direction: column;
        }

        img {
          width: 27px;
          height: 27px;
          object-fit: contain;
          object-position: center;
          margin-right: 12px;

          @media screen and (max-width: 768px) {
            margin-right: 0;
          }
        }

        span {
          font-weight: 300;
          font-size: 12px;

          @media screen and (max-width: 768px) {
            display: none;
          }
        }

        strong {
          font-weight: 500;
          font-size: 14px;
        }
      }
    }
  }

  &_recomended {
    margin-top: 45px;
    margin-bottom: 67px;

    @media screen and (max-width: 1200px) {
      margin-top: 55px;
      margin-bottom: 40px;
    }

    @media screen and (max-width: 768px) {
      display: none;
    }

    &-slider {
      margin-bottom: 10px;

      @media screen and (max-width: 768px) {
        height: 0;
        overflow: hidden;
      }
    }
  }

  &_download {
    display: flex;

    @media screen and (max-width: 768px) {
      display: none;
    }

    .download-jumbo {
      margin-bottom: 0;
    }
  }

  &_col_photo {
    display: flex;
  }

  @media screen and (max-width: 768px) {
    &_container {
      max-width: 100%;
      display: flex;
      padding-right: 0;
      padding-left: 0;
    }

    &_row {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }

    &_col {
      &_photo {
        z-index: 2;
      }

      &_info {
        background-color: #F6F6F6;
        z-index: 1;
      }

      &_photo, &_info {
        padding-left: 0;
        padding-right: 0;
        position: relative;
      }
    }
  }
}
</style>
