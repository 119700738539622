<template>
  <div class="modal-login">
    <div class="modal-login_title">
      <p>Вы зарегистрированы?</p>
    </div>
    <div class="modal-login_text">
      <p>
        Чтобы получить возможность сохранять любимые блюда, вам необходимо войти в ваш личный кабинет либо зарегистрироваться
      </p>
    </div>
    <div class="modal-login_submit">
      <nuxt-link to="/login" tag="div">
        <Button>Зарегистрироваться / Войти</Button>
      </nuxt-link>
    </div>
  </div>
</template>

<script>
import Button from '@/components/UI/Button'

export default {
  components: { Button },
}
</script>

<style lang="less">
.modal-login {
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-top: 60px;

	&_title {
		margin-bottom: 35px;

		p {
			font-family: 'Roboto Slab', Arial, sans-serif;
			font-weight: 500;
			font-size: 32px;
			text-align: center;
		}
	}

	&_text {
		margin-bottom: 35px;

		p {
			font-family: 'Roboto', Arial, sans-serif;
			font-weight: 300;
			font-size: 18px;
			line-height: 1.3;
			text-align: center;
		}
	}

	&_submit {
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}
</style>
