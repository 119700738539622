<template>
  <div class="like_wrap">
    <button :class="{ 'active': isInFavorite }" @click="pushLike" class="like">
      <PageLoader v-if="isLoading" :absolute="false" :fixed="false" />
      <font-awesome-icon :icon="['fas', 'heart']" v-else />
    </button>

    <Modal
      :open="modalLogin"
      @close="modalLogin = false"
      initial-width="540px"
    >
      <ModalLogin @close="modalLogin = false" />
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/page/Modal/Modal'
import ModalLogin from '@/components/page/Modal/ModalLogin'
import _ from 'lodash'
import { mapGetters } from 'vuex'
import PageLoader from '~/components/UI/PageLoader'

export default {
  components: {
	  Modal,
    ModalLogin,
    PageLoader,
  },
  props: {
    uuid: [String, Number],
    like: {
      type: Boolean,
      default () {
        return false
      },
    },
  },
  data () {
    return {
      modalLogin: false,
      isLoading: false,
    }
  },
  methods: {
    pushLike () {
      if (this.$store.getters['auth/loggedIn']) {
			  let action = 'favorites/addItem'
			  if (this.isInFavorite) {
		  		action = 'favorites/deleteItem'
        }
			  this.isLoading = true

        this.$store.dispatch(action, this.uuid)
          .then(() => {
					  if (action === 'favorites/addItem') {
              this.$toast.success('Добавлено в избранные блюда', {
                theme: 'bubble',
                singleton: true,
                duration: 1500,
              })
            }
          })
          .catch((err) => {
            this.$toast.error(err?.response?.data?.message, {
              duration: 3000,
            })
		  		})
          .finally(() => {
				  this.isLoading = false
          })
      } else {
        this.modalLogin = true
      }
    },
  },

  computed: {
	  ...mapGetters({
      favoritesMenuItems: 'favorites/getList',
    }),
	  isInFavorite () {
	  	return _.find(this.favoritesMenuItems, ['id', this.uuid])
    },
  },
}
</script>

<style lang="less">
.like {
	width: 45px;
	height: 45px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border: none;
	border-radius: 50%;
	box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
	font-size: 19px;
	color: #DBDBDB;
	cursor: pointer;
	transition: all .3s;

	@media screen and (max-width: 1200px) {
		width: 37px;
		height: 37px;
		font-size: 16px;
	}

	&:hover, &:focus { transform: scale(1.06); }
	&:active { transform: scale(1); }

	&.active { color: #FF0066; }
}
</style>
