<template>
  <div @click="toggleActive" class="tumbler-prop">
    <label :class="{ 'active': active }" :for="id"><slot /></label>
  </div>
</template>

<script>
export default {
  model: {
    prop: 'active',
    event: 'input',
  },
  props: {
    id: String,
    active: {
      type: Boolean,
      default: () => false,
    },
  },
  methods: {
    toggleActive () {
      this.$emit('input', !this.active)
    },
  },
}
</script>

<style lang="less">
.tumbler-prop {
	label {
		height: 30px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: #F6F6F6;
		font-family: 'Roboto', Arial, sans-serif;
		font-size: 14px;
		color: black;
		padding: 7px 33px;
		border-radius: 10px;
		box-sizing: border-box;
		transition: all .2s;
		cursor: pointer;

		@media screen and (max-width: 1200px) { height: 25px; }

		&.active {
			background: rgb(255,152,0);
			background: linear-gradient(180deg, rgba(255,152,0,1) 0%, rgba(255,78,0,1) 100%);
			color: white;
		}
	}
}
</style>
