<template>
  <div :class="{ 'product_photo--build': isBuild }" class="product_photo">
    <div class="product_attrs_container">
      <div class="product_photo_attrs">
        <div v-if="attrs.marketing === 'personalDiscount'" class="product_attrs_personal">
          персональная скидка
        </div>
        <img v-if="attrs.marketing === 'new'" src="/cardicons/new.svg" class="product_attrs_promote">
        <img v-if="attrs.marketing === 'hit'" src="/cardicons/hit-2.svg" class="product_attrs_promote">
        <img v-if="attrs.marketing === 'percent'" src="/cardicons/promote.svg" class="product_attrs_promote">
        <img v-if="isDoubling" src="/cardicons/sticker-x2.svg" class="product_attrs_promote  card_attrs_promote_x2">
        <!-- <img src="/cardicons/twoinone.svg" class="product_attrs_promote" v-if="attrs.promoteTwoInOne">
				<img src="/cardicons/bx2.svg" class="product_attrs_x2" v-if="attrs.promoteX2"> -->
      </div>
      <div class="product_photo_attrs product_photo_attrs-right">
        <img v-if="attrs.type === 'spicy'" src="/cardicons/spicy.svg" class="product_attrs_spicy">
        <img v-if="attrs.type === 'vegetarian'" src="/cardicons/vedgie.svg" class="product_attrs_vedgie">
        <img v-if="attrs.type === 'child'" src="/cardicons/child.svg" class="card_attrs_child">
      </div>
    </div>

    <!-- <div class="product_like">
			<Like :like="true" />
		</div> -->
    <div v-if="isPizza" class="product_photo_pizza">
      <div class="product_photo_pizza_prop">
        <span>30 см</span>
      </div>
    </div>
    <img :src="photo" v-if="photo && !isBuild" alt="">
    <div v-if="isBuild" class="product_photo_build">
      <div
        v-if="buildLeft"
        :style="`background-image: url('${ buildLeft }')`"
        class="product_photo_build_left"
      />
      <div
        v-if="buildRight"
        :style="`background-image: url('${ buildRight }')`"
        class="product_photo_build_right"
      />
    </div>
  </div>
</template>

<script>
import Like from './../../UI/Like'

export default {
  components: { Like },
  props: {
    photo: {
      type: String,
      default: '',
    },
    attrs: {
      type: Object,
      default () {
        return null
      },
    },
    isPizza: {
      type: Boolean,
      default () {
        return false
      },
    },
    isBuild: {
      type: Boolean,
      default () {
        return false
      },
    },
    isDoubling: {
      type: Boolean,
      default () {
        return false
      },
    },
    buildLeft: {
      type: String,
      default: null,
    },
    buildRight: {
      type: String,
      default: null,
    },
  },
  data () {
    return {
      builder: {
        left: null,
        right: null,
      },
    }
  },
}
</script>

<style lang="less">
.product {
  &_photo {
    width: 100%;
    height: 520px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: @menu-item--photo-bgr;
    border-radius: 30px;
    position: relative;
    overflow: hidden;

    @media screen and (max-width: 1200px) {
      height: 390px;
    }
    @media screen and (max-width: 992px) {
      margin-bottom: 30px;
    }
    @media screen and (max-width: 768px) {
      height: 270px;
      border-radius: 0;
      margin-bottom: 0;
      overflow: visible;
    }

    &_attrs {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 25px;
      left: 25px;

      &-right {
        right: 25px;
        left: unset;
      }

      @media screen and (max-width: 768px) {
        top: unset;
        bottom: 14px;
      }

      img {
        &:not(:last-child) {
          margin-bottom: 10px;
        }
      }

      .card_attrs_promote_x2 {
        width: 50px;
      }
    }

    img {
      max-width: 100%;
      max-height: 100%;
      width: auto;
      height: auto;
      flex-shrink: 0;
    }

    &_pizza {
      width: 370px;
      height: 370px;
      background-image: url('/pizzaround.png');
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @media screen and (max-width: 768px) {
        width: 230px;
        height: 230px;
      }

      &_prop {
        position: absolute;
        top: calc(100% + 11px);
        left: 50%;
        transform: translateX(-50%);

        @media screen and (max-width: 768px) {
          top: calc(100% + 2px);
        }

        span {
          display: flex;
          align-items: center;
          background-image: url('/diameter.svg');
          background-size: contain;
          background-position: center left;
          background-repeat: no-repeat;
          font-family: 'Roboto Slab', Arial, sans-serif;
          font-weight: 500;
          font-size: 15px;
          color: #C6C6C6;
          padding-left: 14px;
        }

        & + img {
          width: 340px;
          height: 340px;
          object-fit: contain;
          object-position: center;

          @media screen and (max-width: 768px) {
            width: 210px;
            height: 210px;
          }
        }
      }
    }

    &_build {
      width: 340px;
      height: 340px;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        width: 210px;
        height: 210px;
      }

      &_left, &_right {
        width: calc(50% - 4px);
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
      }

      &_left {
        background-position: top left;
      }

      &_right {
        background-position: top right;
      }
    }

    &--build {
      height: 450px;
      overflow: visible;

      @media screen and (max-width: 768px) {
        height: 270px;
      }

      .product {
        &_like {
          top: unset !important;
          bottom: -17px;
        }
      }
    }
  }

  &_like {
    display: inline-block;
    position: absolute;
    top: 28px;
    right: 26px;

    @media screen and (max-width: 768px) {
      top: unset;
      bottom: -17px;
      right: 16px;
    }
  }
}
</style>
