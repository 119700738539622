<template>
  <div :class="{ 'mini': mini }" class="button-tmblr">
    <button
      :class="{ 'active': !active }"
      :disabled="disabled"
      @click="$emit('input', false)"
      class="button-tmblr_item"
    >
      {{ nameOff }}
    </button>
    <button
      :class="{ 'active': active }"
      :disabled="disabled"
      @click="$emit('input', true)"
      class="button-tmblr_item"
    >
      {{ nameOn }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    mini: {
      type: Boolean,
      default () {
        return false
      },
    },
    nameOn: String,
    nameOff: String,
    active: Boolean,
    disabled: Boolean,
  },
}
</script>

<style lang="less">
.button-tmblr {
  height: 40px;
  display: inline-flex;
  align-items: stretch;
  background-color: #F6F6F6;
  border-radius: 10px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }

  &_item {
    min-width: 116px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    padding: 10px 25px;
    border: none;
    border-radius: 10px;
    font-family: 'Roboto', Arial, sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: black;
    box-sizing: border-box;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      flex-grow: 1;
    }

    &.active {
      background: @color-primary;
      background: @gradient-primary;
      color: white;
    }
  }

  &.mini {
    height: 30px;

    @media screen and (max-width: 1200px) {
      height: 25px;
    }
    @media screen and (max-width: 768px) {
      width: auto;
    }

    .button-tmblr_item {
      min-width: unset;
      padding: 5px 18px;

      @media screen and (max-width: 1200px) {
        font-size: 12px;
        padding: 5px 18px;
      }

      &.active {
        background: white;
        box-shadow: 0 3px 6px rgba(0, 0, 0, .16);
        color: #003CFF;
      }
    }
  }
}
</style>
